<template>
  <div class="log">
    <v-btn
      class="white--text"
      @click.stop="dialog = false"
      @click="check()"
      v-permissions="'add-gps-device'"
      :disabled="processing"
      flat
      color="orange darken-1"
    >
      <v-icon>add</v-icon>&nbsp;Add IMEI Devices
    </v-btn>

    <v-dialog v-model="dialog" max-width="35%" persistent>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Add New Deivces</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="cancel()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer>
            <v-progress-linear
              indeterminate
              color="trukkin-theme darken-2"
              v-show="processing"
            ></v-progress-linear>
            <v-card-text class="nospace">
              <v-container class="p-16">
                <v-layout row class="py-4 pt-5">
                  <v-select
                    v-model="countrySortName"
                    label="Select Working country"
                    class="pt-0 currencyTitle"
                    :items="workingCountries"
                    item-text="value"
                    item-value="value"
                    outline
                    :rules="[rules.requiredLength]"
                    single-line
                  />
                </v-layout>
                <v-layout row>
                  <v-text-field
                    label="IMEI Number"
                    single-line
                    v-model="imeiNumber"
                    type="number"
                    outline
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-layout>
              </v-container>
              <div
                class="my-3"
                v-if="haserror"
                style="
                  color: red;
                  width: 50%;
                  border: 0px;
                  margin: auto;
                  text-align: center;
                "
              >
                {{ x.error }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="processing" flat @click="cancel()"
                >CANCEL</v-btn
              >
              <button
                target="_blank"
                :disabled="processing"
                @click="addDevice()"
                type="button"
                class="orange-btn"
              >
                Add
              </button>
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import { imeiSectionApiUrl } from "@/constants/api-urls.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { StorageKeys, Currencies } from "../../constants/constants";
export default {
  data() {
    return {
      multipleCountry: [
        { value: "KSA", currency: "SAR" },
        { value: "UAE", currency: "AED" },
        { value: "PAK", currency: "PKR" },
        { value: "UAE LA", currency: "AED" },
      ],
      countrySortName: "",
      valid: true,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog1: false,
      operationName: "",
      x: {
        error: null,
        jobPrice: null,
        customerCurrency: null,
        subJobPrice: null,
      },
      amount: null,
      items: [],
      currencies: [],
      processing: false,
      reason: "",
      imeiNumber: null,
      dialog: false,
      disStatus: false,
      haserror: false,
      loading: false,
      rules: {
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        required: (value) => !!value || "This field is required.",
        requiredLength: (value) => !!value.length || "This field is required.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },
    };
  },
  props: ["jobId", "detail", "subJobDetail", "working", "viewOnly"],

  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
    }
    this.x.error = null;
    this.haserror = false;
  },
  components: {
    ErrorBox,
    SuccessDialog,
  },
  methods: {
    async check() {
      this.operationName = "add-gps-device";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.open();
      } else {
        return;
      }
    },
    cancel() {
      this.processing = false;
      this.x.success = false;
      this.dialog = false;
      this.imeiNumber = null;
      this.countrySortName = "";
      this.$refs.form.resetValidation();
    },
    open() {
      this.dialog = true;
      this.x.error = null;
      this.haserror = false;
    },
    async addDevice() {
      this.processing = true;
      this.operationName = "add-gps-device";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (this.$refs.form.validate()) {
          this.processing = true;
          let url = imeiSectionApiUrl.addImei;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          var body = {
            workingCountry: this.countrySortName,
            imei: this.imeiNumber,
          };
          this.axios.post(this.constants.apiUrl + url, body, config).then(
            (response) => {
              this.cancel();
              this.x.error = null;
              this.$emit("add-Imei");
              this.processing = false;
              this.haserror = false;
            },
            (error) => {
              this.processing = false;
              this.dialog = true;
              this.haserror = true;
              this.x.error = error.response.data.message;
            }
          );
        }
      } else {
        this.processing = false;
        return;
      }
    },
  },
};
</script>
<style scoped>
.orange-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: darkorange;
  border: 2px solid darkorange;
  padding: 5px 35px;
}
.comments-view .v-toolbar {
  width: 100%;
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
